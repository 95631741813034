<template>
  <div>
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader v-if="templete == 0" />
      <AppHeaderLanding v-else-if="templete == 1" />
      <div class="body flex-grow-1 px-2 pb-2">
        <div class="container">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import AppHeader from '@/components/AppHeader.vue'
import AppHeaderLanding from '@/components/AppHeaderLanding.vue'

export default {
  name: 'DefaultLayout',
  components: {
    AppHeader,
    AppHeaderLanding,
  },
  data() {
    return {
      templete: 1,
    }
  },
  async mounted() {
    await this.callwhitelist()
    await this.setlayout_bg()
  },
  methods: {
    async callwhitelist() {
      this.$store.commit('setagentid', sessionStorage.getItem('aid'))
    },
    async setlayout_bg() {
      this.$store.commit('setapiname', 12002)
      this.$store.commit('setAPI')
      await axios
        .post(this.$store.getters.API, {
          agent_id: this.$store.getters.agent_id,
        })
        .then((resp) => {
          console.log('setlayout_bg')
          if (resp.data.status == 200) {
            console.log('templete', resp.data.result.template_no)
            this.templete = resp.data.result.template_no
          }
        })
        .catch((error) => {
          console.log('setlayout_bg', error)
        })
    },
  },
}
</script>
