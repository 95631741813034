<template>
  <h4 class="text-center text-content-color">มินิเกมส์</h4>
  <div class="container my-2">
    <div
      v-if="geted_reward"
      class="row justify-content-md-center row-cols-md-3 g-0 p-3 my-2 border border-white"
    >
      <div
        v-for="index in item_reward"
        :key="index"
        class="col"
        @click="get_reword(index)"
      >
        <a :id="index" class="btn link-light badge-checkin mb-3">
          <img :src="Close_Teasure" width="100" height="100" />
        </a>
      </div>
    </div>
    <div
      v-else
      class="row justify-content-md-center row-cols-md-3 g-0 p-3 my-2 border border-white"
    >
      <div v-for="(item, index) in list_reward" :key="item" class="col">
        <a class="btn link-light badge-checkin mb-3">
          <div>
            <img
              v-if="index != index_reward - 1"
              :src="Close_Teasure"
              width="100"
              height="100"
              class="mf-2"
            />
            <img v-else :src="Open_Teasure" width="100" height="100" />
            <div class="number-timec">{{ item }} บาท</div>
          </div>
        </a>
      </div>
    </div>
    <div class="text-center text-content-color">
      <h6>เลือกเหรีญรางวัล 1 เหรียญเพื่อรับรางวัล ( 1 เหรียญ / 1 ตั๋ว )</h6>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
// import moment from 'moment'
import Close_Teasure from '@/assets/images/Close_Teasure.png'
import Open_Teasure from '@/assets/images/Open_Teasure.png'

export default {
  name: 'GamesReword',
  components: {},
  data() {
    return {
      ticket_count: 3,
      item_reward: 5,
      geted_reward: true,
      list_reward: [],
      index_reward: 0,
    }
  },
  setup() {
    return {
      Close_Teasure,
      Open_Teasure,
    }
  },
  methods: {
    random_list() {
      this.list_reward.sort(function () {
        return 0.5 - Math.random()
      })
    },
    geted_rewardd() {
      this.geted_reward = true
    },
    async get_reword(e) {
      Swal.fire({
        title: 'ใช้ตั๋วเพื่อแลกรางวัล ใช่หรือไม่ ?',
        text: 'ตั๋ว 1 ใบ สามารถใช้แลกได้เพียง 1 รางวัล เท่านั้น',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#42bd41',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.commit('setapiname', 51006)
          this.$store.commit('setAPI')
          const token = sessionStorage.getItem('token')
          const headers = { Authorization: 'Bearer ' + token }
          let ticket = this.$store.getters.minigame
          this.index_reward = e
          axios
            .post(
              this.$store.getters.API,
              {
                ticket_id: ticket._id,
                index_reward: this.index_reward,
              },
              {
                headers,
              },
            )
            .then((resp) => {
              console.log('get_reword')
              setTimeout(function () {
                document.querySelector('button#btngetticket').click()
                // document.querySelector('button#btnreward').click()
                document.querySelector('button#btnminigameall').click()
                document.querySelector('#btnrefreshprofile').click()
              }, 10)
              if (resp.data.status == 200) {
                this.list_reward = resp.data.reward
                Swal.fire(
                  'สำเร็จ!!!',
                  'ได้รับรางวัล ' + resp.data.reward_amount + ' บาท',
                  'success',
                )
              } else if (resp.data.status == 203 || resp.data.status == 201) {
                setTimeout(function () {
                  document.querySelector('button#btnclosedegamereword').click()
                }, 10)
                Swal.fire({
                  title: 'ผิดพลาด!!!',
                  text: resp.data.message,
                  icon: 'error',
                  confirmButtonText: 'ตกลง',
                })
              } else {
                console.log('get_reword =>', resp.data)
              }
              this.geted_reward = false
            })
            .catch((error) => {
              console.log('get_reword =>', error)
            })
        }
      })
    },
  },
  mounted() {},
}
</script>

<style scoped>
@import './../styles/_checkin.css';
</style>
