<template>
  <div class="container-pro">
    <h1 class="text-white my-2 mb-4">{{ proname }}</h1>
    <!-- //---------card-----------// -->
    <div class="cardpromo">
      <img :src="img" width="400" height="140" />
      <ul>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
    <!-- //-----detail-----// -->
    <div class="my-3">
      <h3>รายละเอียดโปรโมชั่น</h3>
      <div class="detail-promotion py-2 px-3 mb-1">
        <strong><p v-html="detail" /></strong>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      v-if="this.submitting == false"
      class="btn button-member btn-lg f-bold"
      @click="submitpromotion()"
    >
      กดเพื่อรับโปรโมชั่น
    </button>
    <button
      v-else
      class="btn button-member btn-lg f-bold"
      type="button"
      tabindex="-1"
      disabled
    >
      กรุณารอสักครู่...
      <span
        class="spinner-grow spinner-grow-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </button>
  </div>
  <button id="detailpromotion" @click="setdetailpromotion()" hidden />
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import moment from 'moment'

export default {
  name: 'Promotion',
  components: {},
  data() {
    return {
      submitting: false,
      detail: '',
      proname: '',
      img: '',
      id: '',
      status: '',
    }
  },
  methods: {
    async submitpromotion() {
      if (this.submitting == false) {
        this.submitting = true
        Swal.fire({
          title: 'ยืนยันรับโปรโมชั่น ?',
          text: 'กรุณาอ่านรายละเอียดโปรโมชั่นก่อนกดรับเสมอ',
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#42bd41',
          cancelButtonColor: '#d33',
          confirmButtonText: 'ยันยันรับโปรโมชั่น',
          cancelButtonText: 'ยกเลิก',
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store.commit('setapiname', 11022)
            this.$store.commit('setAPI')
            const token = sessionStorage.getItem('token')
            const headers = { Authorization: 'Bearer ' + token }
            console.log('submitpromotion')
            axios
              .post(
                this.$store.getters.API,
                {
                  promotion_id: this.id,
                },
                {
                  headers,
                },
              )
              .then((res) => {
                if (res.data.status == 200) {
                  document.querySelector('button#btnpromotionall').click()
                  Swal.fire({
                    title: 'รับโปรโมชั่นสำเร็จ!!!',
                    text: res.data.message,
                    icon: 'success',
                    confirmButtonText: 'ตกลง',
                  })
                  setTimeout(function () {
                    document.querySelector('button#btnclosedepromo').click()
                  }, 100)
                  setTimeout(function () {
                    document.querySelector('button#btnclosepromo').click()
                  }, 400)
                  setTimeout(function () {
                    document.querySelector('button#dpbtn').click()
                  }, 800)
                } else if (res.data.status == 201) {
                  Swal.fire({
                    title: 'ผิดพลาด!!!',
                    text: res.data.message,
                    icon: 'error',
                    confirmButtonText: 'ตกลง',
                  })
                } else if (res.data.status == 503) {
                  Swal.fire({
                    title: 'ผิดพลาด!!!',
                    text: 'พบการ Login ซ้อนกรุณาติดต่อเจ้าหน้าที่หรือ Login ใหม่อีกครั้ง',
                    icon: 'error',
                    confirmButtonText: 'ตกลง',
                  })
                  sessionStorage.removeItem('token')
                  sessionStorage.removeItem('aid')
                  this.$store.commit('clearall')
                  this.$router.push('/home')
                  setTimeout(function () {
                    document.querySelector('button#login').click()
                  }, 500)
                } else if (res.data.status == 502 || res.data.status == 504) {
                  Swal.fire({
                    title: 'ผิดพลาด!!!',
                    text: 'กรุณา Login ใหม่อีกครั้ง',
                    icon: 'error',
                    confirmButtonText: 'ตกลง',
                  })
                  sessionStorage.removeItem('token')
                  sessionStorage.removeItem('aid')
                  this.$store.commit('clearall')
                  this.$router.push('/home')
                  setTimeout(function () {
                    document.querySelector('button#login').click()
                  }, 500)
                } else {
                  console.log('Call Select Promotion : ', res.data)
                }
              })
              .catch((error) => {
                console.error('error Call Select Promotion =>', error)
                // Swal.fire({
                //   title: 'ผิดพลาด!!!',
                //   text: 'ระบบขัดข้องกรุณา ติดต่อเจ้าหน้าที่',
                //   icon: 'error',
                //   confirmButtonText: 'ตกลง',
                // })
              })
          }
        })
        this.submitting = false
      }
    },
    setdetailpromotion() {
      this.detail = this.$store.getters.promotion_detail
      this.proname = this.$store.getters.promotion_name
      this.img = this.$store.getters.promotion_image
      this.id = this.$store.getters.promotion_id
      this.status = this.$store.getters.promotion_status
    },
    replaceStatus(status) {
      if (
        status == 'failed' ||
        status == 'fail' ||
        status == 'processing' ||
        status == 'check'
      ) {
        status = 'pending'
      } else if (status == 'success') {
        status = 'approve'
      }
      return status
    },
    getImg(pic) {
      if (
        pic == 'failed' ||
        pic == 'fail' ||
        pic == 'processing' ||
        pic == 'check'
      ) {
        pic = 'pending'
      } else if (pic == 'success') {
        pic = 'approve'
      }
      return require('../assets/images/' + pic + '.png')
    },
    Convert_formatdate(input) {
      return moment(input).format('DD / MM / yyyy')
    },
    Convert_formattime(input) {
      return moment(input).format('HH : mm : ss')
    },
  },
  mounted() {
    this.setdetailpromotion()
  },
}
</script>

<style scoped>
@import './../styles/_promotion.css';
</style>
