<template>
  <div class="containers">
    <Spinner />
    <div>
      <p>l</p>
      <p>o</p>
      <p>a</p>
      <p>d</p>
      <p>i</p>
      <p>n</p>
      <p>g</p>
    </div>
  </div>
</template>

<script>
import Spinner from './../components/LoadingBar.vue'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  name: 'Loading',
  components: { Spinner },
  data() {
    return {
      contact: [],
      templete: this.$store.getters.templete,
    }
  },
  mounted() {
    this.callwhitelist()
  },

  methods: {
    handleIcon() {
      const favicon = document.getElementById('favicon')
      favicon.href = 'https://www.google.com/favicon.ico'
    },
    newtab(URL) {
      window.open(URL, '_blank')
    },
    async callwhitelist() {
      this.$store.commit('setapiname', 11013)
      this.$store.commit('setAPI')
      await axios
        .post(this.$store.getters.API, {
          //****************IF Deploy Remark**************** */
          // domain_name: 'https://www.goodluck711.com',
          //************************************************ */
        })
        .then((resp) => {
          console.log('callwhitelist')
          if (resp.data.result.Line == true) {
            let url = window.location.href
            url = url + '?openExternalBrowser=1'
            window.location.href = url
          } else {
            sessionStorage.setItem('device', resp.data.result.device)
            sessionStorage.setItem('aid', resp.data.result.agent_id)
            this.$store.commit('setagentid', sessionStorage.getItem('aid'))
            this.routerpage()
          }
        })
        .catch((error) => {
          console.log(error)
          Swal.fire({
            title: 'ผิดพลาด!!!',
            text: 'ระบบผิดพลาดกรุณา เข้าใหม่อีกครั้ง',
            icon: 'error',
            confirmButtonText: 'ตกลง',
          })
        })
    },
    async routerpage() {
      this.$store.commit('setapiname', 12002)
      this.$store.commit('setAPI')
      await axios
        .post(this.$store.getters.API, {
          agent_id: this.$store.getters.agent_id,
        })
        .then((resp) => {
          console.log('routerpage')
          if (resp.data.status == 200) {
            let list = resp.data.result.layout
            for (let i = 0; i < list.length; i++) {
              if (list[i].type == 'contact') {
                this.contact = list[i].data
              }
            }
            this.$store.commit('setsocial_line', this.contact.left_url)
            console.log('templete', resp.data.result.template_no)
            if (resp.data.result.template_no == '0') {
              this.$router.push('/login')
            } else if (resp.data.result.template_no == '1') {
              this.$router.push('/default')
            }
          } else {
            this.$router.push('/login')
          }
        })
        .catch((error) => {
          console.log('routerpage', error)
        })
    },
  },
  setup() {
    return {}
  },
}
</script>

<style scoped>
.containers {
  display: flex;
  width: 100vw;
  height: 100vh;
  background: black;
  align-items: center;
  justify-content: center;
}
* {
  margin: 0;
}

div {
  text-align: center;
}

p {
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  font-size: 2em;
  font-family: arial;
  font-weight: 900;
  transform: scale(0.5);
  color: #7e7a7a;
  -webkit-text-stroke: 2px rgb(255, 255, 255);
}
</style>
