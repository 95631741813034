<template>
  <div class="sticky-top fixed-top">
    <!-- Navbar -->
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <!-- Container wrapper -->
      <div class="container">
        <!-- Navbar brand -->
        <div class="navbar-nav me-auto mb-lg-0">
          <div class="nav-item">
            <a class="nav-link" href="#" @click="this.$router.push('/')">{{
              this.$store.getters.webname
            }}</a>
          </div>
        </div>

        <!-- Toggle button -->
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <i class="bi bi-list"></i>
        </button>

        <!-- Collapsible wrapper -->
        <div class="navbar-collapse collapse" id="navbar">
          <!-- Left links -->
          <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>
          <!-- Left links -->
          <div class="d-flex align-items-center">
            <a
              type="button"
              class="btn btn-link px-2 text-decoration-none link-light"
              @click="this.newtab(this.$store.getters.social_line)"
            >
              ติดต่อเรา
            </a>
          </div>
        </div>
        <!-- Collapsible wrapper -->
      </div>
      <!-- Container wrapper -->
    </nav>
    <!-- Navbar -->
  </div>

  <!-- Collapsible offcanvas -->
  <div
    class="offcanvas offcanvas-end bg-dark"
    id="offcanvasRight"
    aria-labelledby="offcanvasRightLabel"
  >
    <!-- Left links -->
    <div class="offcanvas-header">
      <li class="nav-item">
        <h1 class="text-decoration-none link-light" data-bs-dismiss="offcanvas">
          {{ this.$store.getters.webname }}
        </h1>
      </li>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <!-- Left links -->
    <div class="offcanvas-body">
      <div class="d-flex align-items-center">
        <ul data-bs-dismiss="offcanvas">
          <li>
            <button
              type="button"
              class="btn btn-link px-2 text-decoration-none link-light"
              @click="this.$router.push('/')"
            >
              <i class="bi bi-house-fill mx-3" style="font-size: 2rem"></i>
              หน้าบ้าน
            </button>
          </li>
          <li>
            <a
              type="button"
              class="btn btn-link px-2 text-decoration-none link-light"
              @click="this.newtab(this.$store.getters.social_line)"
            >
              <i
                class="bi bi-telephone-forward-fill mx-3"
                style="font-size: 2rem"
              ></i>
              ติดต่อเรา
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div>
      <div class="offcanvas-footer">
        <ul class="text-decoration-none link-light mx-3 my-4">
          <strong>ติดต่อผ่านช่องทางออนไลน์</strong>
          <p class="mb-3">
            <a
              @click="this.newtab(this.$store.getters.social_line)"
              class="link-warning"
              data-bs-dismiss="offcanvas"
            >
              <img fluid :src="imgSocial.line" width="50" class="ms-1 me-1" />
              @{{ this.$store.getters.webname }}
            </a>
          </p>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { imgSocialMedia as imgSocial } from '@/assets/images/social'
import axios from 'axios'

export default {
  name: 'AppHeader',
  data() {
    return {
      ipinfo: {},
    }
  },
  setup() {
    return {
      imgSocial,
    }
  },
  async mounted() {
    this.getvanip()
  },
  methods: {
    newtab(URL) {
      window.open(URL, '_blank')
    },
    clickback() {
      setTimeout(function () {
        document.querySelector('.swiper-button-prev').click()
        document.querySelector('.swiper-button-prev').click()
      }, 500)
    },
    async getvanip() {
      await axios.get('https://ipinfo.io/json').then((response) => {
        this.ipinfo = response.data
        console.log('ipinfo')
      })
    },
    clearValue() {
      this.$store.commit('clearall')
    },
  },
}
</script>
