import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueClipboard from 'vue3-clipboard'
import QrReader from 'vue3-qr-reader'
import VueCountdown from '@chenfengyuan/vue-countdown'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(QrReader)
app.component(VueCountdown.name, VueCountdown)
app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
})

app.mount('#app')

import 'bootstrap/dist/js/bootstrap.js'
