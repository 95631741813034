<template>
  <div class="theme">
    <div class="sticky-top fixed-top">
      <!-- Navbar -->
      <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
        <!-- Container wrapper -->
        <div class="container">
          <!-- Navbar brand -->
          <!-- <a class="navbar-brand me-2" href="https://mdbgo.com/">
          <img
            src="https://mdbcdn.b-cdn.net/img/logo/mdb-transaprent-noshadows.webp"
            height="16"
            alt="MDB Logo"
            loading="lazy"
            style="margin-top: -1px"
          />
        </a> -->
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item px-auto">
              <router-link
                v-if="logo == ''"
                to="/member"
                class="nav-link pointer"
                href="#"
                >{{ this.$store.getters.webname }}</router-link
              >
              <img
                v-else
                :src="logo"
                height="30"
                @click="navigate()"
                class="pointer my-1"
              />
            </li>
          </ul>

          <div
            class="mx-3 ms-auto rounded-pill bg-white text-dark txt-nav px-2"
          >
            <span>
              <a
                id="btnrefreshprofile"
                class="bi bi-arrow-repeat mx-1"
                href="#"
                @click="refreshprofile"
              ></a>
            </span>
            <span>
              <strong>ยอดเงิน : {{ this.$store.getters.credit }} ฿ </strong>
            </span>
          </div>

          <!-- Toggle button -->
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
          >
            <i class="bi bi-list"></i>
          </button>

          <!-- Collapsible wrapper -->
          <div class="navbar-collapse collapse" id="navbar">
            <!-- Left links -->
            <div class="navbar-nav ms-auto align-items-center">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <!-- <li class="nav-item">
              <router-link to="/member" class="nav-link" href="#"
                >BANPONG-888</router-link
              >
            </li> -->
              </ul>
              <!-- Left links -->
              <div class="d-flex align-items-center">
                <li class="nav-item" hidden>
                  <button @click="startCountdown()" id="NCTime">start</button>
                  <button @click="stopcounting()" id="NECTime">end</button>
                  <span class="text-decoration-none link-light">
                    <vue-countdown
                      v-if="counting"
                      :transform="getseconds"
                      :time="TimeC"
                      v-slot="{ totalSeconds }"
                      @end="onCountdownEnd()"
                    >
                      {{ totalSeconds }} วินาที</vue-countdown
                    >
                  </span>
                </li>
                <li class="nav-item">
                  <router-link
                    to="/member"
                    type="button"
                    class="btn btn-link px-2 text-decoration-none link-light"
                  >
                    หน้าบ้าน
                  </router-link>
                </li>
                <li class="nav-item">
                  <a
                    type="button"
                    class="btn btn-link px-2 text-decoration-none link-light"
                    @click="this.newtab(line_url)"
                  >
                    ติดต่อเรา
                  </a>
                </li>
                <li class="nav-item">
                  <!-- <button
                    type="button"
                    id="dpbtn"
                    class="btn btn-link px-2 text-decoration-none link-light"
                    data-bs-toggle="modal"
                    data-bs-target="#modalDeposit"
                    @click="refreshprofile(), getbankdp()"
                  >
                    ฝากเงิน
                  </button> -->
                  <button
                    type="button"
                    id="dpbtn"
                    class="btn btn-link px-2 text-decoration-none link-light"
                    @click="this.$router.push('/member/deposit')"
                  >
                    ฝากเงิน
                  </button>
                </li>
                <li class="nav-item">
                  <!-- <button
                    type="button"
                    class="btn btn-link px-2 text-decoration-none link-light"
                    data-bs-toggle="modal"
                    data-bs-target="#modalWithdraw"
                    @click="refreshprofile"
                  >
                    ถอนเงิน
                  </button> -->
                  <button
                    type="button"
                    class="btn btn-link px-2 text-decoration-none link-light"
                    @click="this.$router.push('/member/withdraw')"
                  >
                    ถอนเงิน
                  </button>
                </li>
                <button
                  type="button"
                  class="btn btn-secondary mx-1"
                  data-bs-toggle="modal"
                  data-bs-target="#modalLoginID"
                  @click="cleartoken"
                >
                  ออกจากระบบ
                  <i class="bi bi-box-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
          <!-- Collapsible wrapper -->
        </div>
        <!-- Container wrapper -->
      </nav>
      <!-- Navbar -->
    </div>
    <!-- Collapsible offcanvas -->
    <div
      class="offcanvas offcanvas-end bg-dark"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <!-- Left links -->
      <div class="offcanvas-header">
        <li class="nav-item">
          <h1
            v-if="logo2 == ''"
            class="text-decoration-none link-light"
            data-bs-dismiss="offcanvas"
          >
            {{ this.$store.getters.webname }}
          </h1>
          <img v-else :src="logo" height="100" class="pointer my-1" />
        </li>

        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <!-- Left links -->
      <div class="offcanvas-body">
        <div class="d-flex align-items-center">
          <ul data-bs-dismiss="offcanvas">
            <li class="nav-item">
              <router-link
                to="/member"
                type="button"
                class="btn btn-link px-2 text-decoration-none link-light"
              >
                <i class="bi bi-house mx-3" style="font-size: 2rem"></i>
                หน้าบ้าน
              </router-link>
            </li>
            <li class="nav-item">
              <!-- <button
                type="button"
                class="btn btn-link px-2 text-decoration-none link-light"
                data-bs-toggle="modal"
                data-bs-target="#modalDeposit"
                @click="refreshprofile(), getbankdp()"
              >
                <i class="bi bi-wallet2 mx-3" style="font-size: 2rem"></i>
                ฝากเงิน
              </button> -->
              <button
                type="button"
                class="btn btn-link px-2 text-decoration-none link-light"
                @click="this.$router.push('/member/deposit')"
              >
                <i class="bi bi-wallet2 mx-3" style="font-size: 2rem"></i>
                ฝากเงิน
              </button>
            </li>
            <li class="nav-item">
              <!-- <button
                type="button"
                class="btn btn-link px-2 text-decoration-none link-light"
                data-bs-toggle="modal"
                data-bs-target="#modalWithdraw"
                @click="refreshprofile"
              >
                <i class="bi bi-cash-coin mx-3" style="font-size: 2rem"></i>
                ถอนเงิน
              </button> -->
              <button
                type="button"
                class="btn btn-link px-2 text-decoration-none link-light"
                @click="this.$router.push('/member/withdraw')"
              >
                <i class="bi bi-cash-coin mx-3" style="font-size: 2rem"></i>
                ถอนเงิน
              </button>
            </li>
            <li class="nav-item">
              <!-- <button
                type="button"
                class="btn btn-link px-2 text-decoration-none link-light"
                data-bs-toggle="modal"
                data-bs-target="#modalhis"
              >
                <i class="bi bi-card-heading mx-3" style="font-size: 2rem"></i>
                ประวัติ
              </button> -->
              <button
                type="button"
                class="btn btn-link px-2 text-decoration-none link-light"
                @click="this.$router.push('/member/history')"
              >
                <i class="bi bi-card-heading mx-3" style="font-size: 2rem"></i>
                ประวัติ
              </button>
            </li>
            <li class="nav-item">
              <!-- <button
                type="button"
                class="btn btn-link px-2 text-decoration-none link-light"
                data-bs-toggle="modal"
                data-bs-target="#modalpromo"
              >
                <i class="bi bi-gift mx-3" style="font-size: 2rem"></i>
                โปรโมชั่น
              </button> -->
              <button
                type="button"
                class="btn btn-link px-2 text-decoration-none link-light"
                @click="this.$router.push('/member/promotion')"
              >
                <i class="bi bi-gift mx-3" style="font-size: 2rem"></i>
                โปรโมชั่น
              </button>
            </li>
            <li class="nav-item">
              <!-- <button
                type="button"
                class="btn btn-link px-2 text-decoration-none link-light"
                data-bs-toggle="modal"
                data-bs-target="#modalvoucher"
              >
                <i
                  class="bi bi-ticket-perforated mx-3"
                  style="font-size: 2rem"
                ></i>
                Voucher
              </button> -->
              <button
                type="button"
                class="btn btn-link px-2 text-decoration-none link-light"
                @click="this.$router.push('/member/voucher')"
              >
                <i
                  class="bi bi-ticket-perforated mx-3"
                  style="font-size: 2rem"
                ></i>
                คูปองรางวัล
              </button>
            </li>
            <li class="nav-item">
              <!-- <button
                type="button"
                class="btn btn-link px-2 text-decoration-none link-light"
                data-bs-toggle="modal"
                data-bs-target="#modalminigame"
              >
                <i class="bi bi-joystick mx-3" style="font-size: 2rem"></i>
                มินิเกมส์
              </button> -->
              <button
                type="button"
                class="btn btn-link px-2 text-decoration-none link-light"
                @click="this.$router.push('/member/minigame')"
              >
                <i class="bi bi-joystick mx-3" style="font-size: 2rem"></i>
                มินิเกมส์
              </button>
            </li>
            <li class="nav-item">
              <button
                type="button"
                class="btn btn-link px-2 text-decoration-none link-light"
                @click="this.$router.push('/member/commission')"
              >
                <i class="bi bi-people mx-3" style="font-size: 2rem"></i>
                คอมมิชชั่น
              </button>
            </li>
            <!-- <li class="nav-item">
              <button
                type="button"
                class="btn btn-link px-2 text-decoration-none link-light"
                data-bs-toggle="modal"
                data-bs-target="#modalcheckin"
              >
                <i class="bi bi-calendar3 mx-3" style="font-size: 2rem"></i>
                เช็คอิน
              </button>
            </li> -->
            <!-- <li class="nav-item">
            <button
              type="button"
              class="btn btn-link px-2 text-decoration-none link-light"
              disabled
              data-bs-toggle="modal"
              data-bs-target="#modalsoon"
            >
              <i class="bi bi-person-badge mx-3" style="font-size: 2rem"></i>
              ข้อมูลส่วนตัว
            </button>
          </li> -->
            <!-- <li class="nav-item">
            <button
              type="button"
              class="btn btn-link px-2 text-decoration-none link-light"
              disabled
              data-bs-toggle="modal"
              data-bs-target="#modalsoon"
            >
              <i class="bi bi-people-fill mx-3" style="font-size: 2rem"></i>
              แนะนำ
            </button>
          </li> -->
            <!-- <li class="nav-item">
            <button
              type="button"
              class="btn btn-link px-2 text-decoration-none link-light"
              disabled
              data-bs-toggle="modal"
              data-bs-target="#modalsoon"
            >
              <i class="bi bi-qr-code-scan mx-3" style="font-size: 2rem"></i>
              QR Code
            </button>
          </li> -->
          </ul>
        </div>
      </div>
      <div>
        <div class="offcanvas-footer">
          <ul class="text-decoration-none link-light mx-3 my-4">
            <button
              type="button"
              class="btn btn-secondary mx-1"
              data-bs-dismiss="offcanvas"
              data-bs-toggle="modal"
              data-bs-target="#modalLoginID"
              @click="cleartoken"
            >
              ออกจากระบบ
              <i class="bi bi-box-arrow-right"></i>
            </button>
          </ul>
        </div>
      </div>
    </div>
    <!-- Modal - Deposit -->
    <!-- <div
      class="modal fade modal"
      id="modalDeposit"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="modalDeposit"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content border-2 modal-shadow">
          <div class="modal-header">
            <h5 class="modal-title text-white" id="modalDeposit">
              <i class="bi bi-piggy-bank-fill"></i>
              ฝากเงิน
            </h5>
            <button
              id="modalDeposit"
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <Deposit />
          </div>
        </div>
      </div>
    </div> -->
    <!-- Modal - Withdraw -->
    <!-- <div
      class="modal fade modal"
      id="modalWithdraw"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="modalWithdraw"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content border-2 modal-shadow">
          <div class="modal-header">
            <h5 class="modal-title text-white" id="modalWithdraw">
              <i class="bi bi-cash-coin"></i>
              ถอนเงิน
            </h5>
            <button
              type="button"
              id="btnwdclose"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="resetvalue"
            ></button>
          </div>
          <div class="modal-body">
            <Withdraw />
          </div>
        </div>
      </div>
    </div> -->
    <!-- Modal - History -->
    <!-- <div
      class="modal fade modal"
      id="modalhis"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="modalhis"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content border-2 modal-shadow">
          <div class="modal-header">
            <h5 class="modal-title text-white" id="modalhis">
              <i class="bi bi-card-heading"></i>
              ประวัติรายการฝาก / รายการถอน
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <History />
          </div>
          <div class="modal-footer">
            <p class="fst-italic small text-danger">
              *รายการฝาก/รายการถอน มีอายุ 1 เดือน
            </p>
          </div>
        </div>
      </div>
    </div> -->
    <!-- Modal - Promotion -->
    <!-- <div
      class="modal fade modal"
      id="modalpromo"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="modalpromo"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content border-2 modal-shadow">
          <div class="modal-header">
            <h5 class="modal-title text-white" id="modalpromo">
              <i class="bi bi-gift"></i>
              โปรโมชั่น
            </h5>
            <button
              id="btnclosepromo"
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeallcollapse_promotion"
            ></button>
          </div>
          <div class="modal-body">
            <div class="container">
              <Promotion />
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- Modal - Detail - Promotion -->
    <div
      class="modal fade modal"
      id="modaldetailpro"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="modaldetailpro"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content border-2 modal-shadow">
          <div class="modal-header">
            <h5 class="modal-title text-white" id="modaldetailpro">
              <i class="bi bi-gift"></i>
              รายระเอียดโปรโมชั่น
            </h5>
            <button
              id="btnclosedepromo"
              type="button"
              class="btn-close btn-close-white"
              data-bs-toggle="modal"
              data-bs-target="#modaldetailpro"
              aria-label="Close"
            ></button>
            <!-- <button
              id="btnclosedepromo"
              type="button"
              class="btn-close btn-close-white"
              aria-label="Close"
            ></button> -->
          </div>
          <div class="modal-body">
            <div class="container">
              <DetailPro />
            </div>
          </div>
          <!-- <div class="modal-footer">
          <button
            class="btn btn-warning"
            data-bs-toggle="modal"
            data-bs-target="#modalpromo"
          >
            ย้อนกลับ
          </button>
        </div> -->
        </div>
      </div>
    </div>
    <!-- Modal - Voucher -->
    <!-- <div
      class="modal fade modal"
      id="modalvoucher"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="modalvoucher"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content border-2 modal-shadow">
          <div class="modal-header">
            <h5 class="modal-title text-white" id="modalvoucher">
              <i class="bi bi-ticket-perforated"></i>
              คูปองรางวัล
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="resetvalue"
            ></button>
          </div>
          <div class="modal-body">
            <Voucher />
          </div>
          <div class="modal-footer">
            <p class="fst-italic small text-danger">
              *หากรหัสไม่สามารถใช้งานได้ กรุณาติดต่อเจ้าหน้าที่
            </p>
          </div>
        </div>
      </div>
    </div> -->
    <!-- Modal - Check-in -->
    <!-- <div
      class="modal fade modal"
      id="modalcheckin"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="modalcheckin"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content border-2 modal-shadow">
          <div class="modal-header">
            <h5 class="modal-title text-white" id="modalcheckin">
              <i class="bi bi-calendar3"></i>
              เช็คอิน
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <Checkin />
          </div>
          <div class="modal-footer">
            <p class="fst-italic small text-danger">
              *หากเช็คอินไม่ได้ หรือพบปัญหา กรุณาติดต่อเจ้าหน้าที่
            </p>
          </div>
        </div>
      </div>
    </div> -->
    <!-- Modal - minigame -->
    <!-- <div
      class="modal fade modal"
      id="modalminigame"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="modalminigame"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content border-2 modal-shadow">
          <div class="modal-header">
            <h5 class="modal-title text-white" id="modalminigame">
              <i class="bi bi-joystick"></i>
              มินิเกมส์
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeallcollapse_minigame"
            ></button>
          </div>
          <div class="modal-body">
            <MiniGames />
          </div>
          <div class="modal-footer">
            <p class="fst-italic small text-danger">
              *หากพบปัญหา กรุณาติดต่อเจ้าหน้าที่
            </p>
          </div>
        </div>
      </div>
    </div> -->
    <!-- Modal - Games Reword -->
    <div
      class="modal fade modal"
      id="modalgamereword"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="modalgamereword"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content border-2 modal-shadow">
          <div class="modal-header">
            <h5 class="modal-title text-white" id="modalgamereword">
              <i class="bi bi-joystick"></i>
              มินิเกมส์
            </h5>
            <button
              id="btnclosedegamereword"
              type="button"
              class="btn-close btn-close-white"
              data-bs-toggle="modal"
              data-bs-target="#modalgamereword"
              aria-label="Close"
              @click="game_reward"
            ></button>
          </div>
          <div class="modal-body">
            <GamesReword />
          </div>
          <div class="modal-footer">
            <p class="fst-italic small text-danger">
              *หากพบปัญหา กรุณาติดต่อเจ้าหน้าที่
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal - commission -->
    <!-- <div
      class="modal fade modal"
      id="modalcommis"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="modalcommis"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content border-2 modal-shadow">
          <div class="modal-header">
            <h5 class="modal-title text-white" id="modalcommis">
              <i class="bi bi-people"></i>
              คอมมิชชั่น
            </h5>
            <button
              id="btnclosecommis"
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeallcollapse_commission"
            ></button>
          </div>
          <div class="modal-body">
            <div class="container">
              <Commission />
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- Modal - coming soon -->
    <div
      class="modal fade modal"
      id="modalsoon"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="modalsoon"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content border-2 modal-shadow">
          <div class="modal-header">
            <h5 class="modal-title text-white" id="modalsoon">
              <i class="bi bi-exclamation-circle mx-2"></i>
              Coming Soon
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <img
              src="https://d1csarkz8obe9u.cloudfront.net/posterpreviews/opening-soon%2C-coming-soon-design-template-2ad6ecb3bfc0d528a9999c00a642d447_screen.jpg?ts=1593776133"
              alt="Coming Soon"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Modal - Spinner -->
    <div
      class="modal fade modal"
      id="modalspin"
      data-bs-backdrop="static"
      data-bs-keyboard="true"
      tabindex="-1"
      aria-labelledby="modalspin"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-body">
          <button
            type="button"
            data-bs-dismiss="modal"
            id="modalspin"
            hidden
          ></button>
          <Spinner />
        </div>
      </div>
    </div>
    <!-- Hidened -->
    <div hidden>
      <button
        id="btngetticket"
        hidden
        @click="get_ticket_minigame(), get_reward_minigame()"
      />
      <button
        id="callspin"
        data-bs-toggle="modal"
        data-bs-target="#modalspin"
      />
    </div>
  </div>
</template>

<script>
// import Deposit from './../components/Deposit.vue'
// import Withdraw from './../components/Withdraw.vue'
// import History from './../components/History.vue'
// import Promotion from './../components/Promotion.vue'
import DetailPro from './../components/DetailPro.vue'
// import Voucher from './../components/Voucher.vue'
// import Checkin from './../components/Checkin.vue'
// import Commission from './../components/Commission.vue'
// import MiniGames from './../components/MiniGames.vue'
import GamesReword from './../components/GamesReword.vue'
import Spinner from './../components/LoadingBar.vue'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  name: 'AppHeaderMember',
  components: {
    // Deposit,
    // Withdraw,
    // History,
    // Promotion,
    DetailPro,
    // Voucher,
    // Checkin,
    // Commission,
    // MiniGames,
    GamesReword,
    Spinner,
  },
  data() {
    return {
      img: this.$store.getters.img_bg,
      logo: '',
      logo2: '',
      counting: false,
      TimeC: 20000, //20sec
      seconds: 0,
      sending: false,
      cooldownf: 0,
      limit_day: false,
      status_deposit_minigame: false,
      contact: [],
      line_url: sessionStorage.getItem('line_url'),
    }
  },
  methods: {
    settimecooldoenf() {
      this.timecooldown = true
    },
    game_reward() {
      setTimeout(function () {
        document.querySelector('button#geted_reward').click()
      }, 500)
    },
    getseconds(props) {
      const formattedProps = {}
      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value
        if (key == 'totalSeconds') {
          this.seconds = value
        }
      })
      return formattedProps
    },
    async startCountdown() {
      this.counting = true
    },
    async onCountdownEnd() {
      if (this.counting == true) {
        this.counting = false
        this.$store.commit('setapiname', 12005)
        this.$store.commit('setAPI')
        const token = sessionStorage.getItem('token')
        const headers = { Authorization: 'Bearer ' + token }
        let time = this.TimeC + 1000
        time = this.TimeC / 1000
        let ticket_time, cooldown_time
        this.cooldownf = sessionStorage.getItem('cdf')
        this.limit_day = this.$store.getters.ticket_limit_day
        this.status_deposit_minigame =
          this.$store.getters.status_deposit_minigame
        if (this.status_deposit_minigame == false) {
          if (this.limit_day == false) {
            if (this.cooldownf == 0) {
              console.log('ticket_t', this.$store.getters.ticket)
              if (this.$store.getters.ticket == false) {
                ticket_time = time
              } else {
                ticket_time = 0
              }
              cooldown_time = 0
            } else {
              cooldown_time = time
              ticket_time = 0
            }
          } else {
            ticket_time = 0
            cooldown_time = 0
          }
        } else {
          ticket_time = 0
          cooldown_time = 0
        }
        await axios
          .post(
            this.$store.getters.API,
            {
              nomal_time: time,
              ticket_time: ticket_time,
              timecooldown: cooldown_time,
            },
            {
              headers,
            },
          )
          .then((res) => {
            if (res.data.status == 200) {
              console.log(
                'Call Time Current Countdown =>',
                res.data.time_current,
              )
              console.log(
                'Call Time Current Cooldown =>',
                res.data.time_current_cooldown,
              )
              setTimeout(function () {
                try {
                  document.querySelector('button#btngetticket').click()
                  document.querySelector('button#btnstack_turnover').click()
                } catch (error) {
                  console.log('=====>click btnstack_turnover<=====')
                }
              }, 100)
            }
          })
          .catch((error) => {
            console.error('error Call Time Normal =>', error)
          })
        setTimeout(function () {
          document.querySelector('button#NCTime').click()
        }, 100)
      }
    },
    async stopcounting() {
      if (this.sending == false) {
        this.sending = true
        this.counting = false
        this.$store.commit('setapiname', 12005)
        this.$store.commit('setAPI')
        const token = sessionStorage.getItem('token')
        const headers = { Authorization: 'Bearer ' + token }
        let time = this.TimeC / 1000
        time = time - this.seconds
        let ticket_time, cooldown_time
        this.cooldownf = sessionStorage.getItem('cdf')
        this.limit_day = this.$store.getters.ticket_limit_day
        this.status_deposit_minigame =
          this.$store.getters.status_deposit_minigame
        if (this.status_deposit_minigame == false) {
          if (this.limit_day == false) {
            if (this.cooldownf == 0) {
              console.log('ticket_t', this.$store.getters.ticket)
              if (this.$store.getters.ticket == false) {
                ticket_time = time
              } else {
                ticket_time = 0
              }
              cooldown_time = 0
            } else {
              cooldown_time = time
              ticket_time = 0
            }
          } else {
            ticket_time = 0
            cooldown_time = 0
          }
        } else {
          ticket_time = 0
          cooldown_time = 0
        }
        await axios
          .post(
            this.$store.getters.API,
            {
              nomal_time: time,
              ticket_time: ticket_time,
              timecooldown: cooldown_time,
            },
            {
              headers,
            },
          )
          .then((res) => {
            if (res.data.status == 200) {
              console.log(
                'Call Time Current Countdown =>',
                res.data.time_current,
              )
              console.log(
                'Call Time Current Cooldown =>',
                res.data.time_current_cooldown,
              )
              setTimeout(function () {
                document.querySelector('button#btngetticket').click()
                document.querySelector('button#btnstack_turnover').click()
              }, 100)
            }
          })
          .catch((error) => {
            console.error('error Call Time Normal =>', error)
          })
        this.sending = false
      }
    },
    async get_reward_minigame() {
      this.$store.commit('setapiname', 51004)
      this.$store.commit('setAPI')
      const token = sessionStorage.getItem('token')
      const headers = { Authorization: 'Bearer ' + token }
      await axios
        .post(
          this.$store.getters.API,
          {},
          {
            headers,
          },
        )
        .then((resp) => {
          console.log('get_reward_minigame')
          if (resp.data.status == 200) {
            this.minigame_noti = resp.data.reward_total
            this.$store.commit(
              'setreward_ticket_minigame',
              resp.data.reward_total,
            )
          } else if (resp.data.status == 201) {
            this.$store.commit('setreward_ticket_minigame', 0)
          }
        })
        .catch((error) => {
          console.log('get_reward_minigame =>', error)
        })
    },
    async get_ticket_minigame() {
      console.log('status_cooldown =', sessionStorage.getItem('cdf'))
      this.$store.commit('setapiname', 51002)
      this.$store.commit('setAPI')
      const token = sessionStorage.getItem('token')
      const headers = { Authorization: 'Bearer ' + token }
      await axios
        .post(
          this.$store.getters.API,
          {},
          {
            headers,
          },
        )
        .then((resp) => {
          if (resp.data.status == 200) {
            this.$store.commit('setlist_ticket_minigame', resp.data.ticket)
            let tk_total = resp.data.ticket_total
            console.log('get-ticket-minigame =>', tk_total)
            this.$store.commit('setticket', false)
            if (tk_total > 0) {
              this.$store.commit('setticket', true)
            }
          } else if (resp.data.status == 201) {
            this.$store.commit('setticket', false)
          }
        })
        .catch((error) => {
          console.log('get-ticket-minigame =>', error)
        })
    },
    navigate() {
      this.$router.push('/member')
    },
    async callwhitelist() {
      this.$store.commit('setagentid', sessionStorage.getItem('aid'))
    },
    changeBackgroundImage(img) {
      const head = document.getElementsByTagName('head')[0]
      const style = document.createElement('style')
      style.innerHTML = `.body {
      background: url(${img}) no-repeat center center fixed;
    }`
      head.appendChild(style)
    },
    async setlayout() {
      this.$store.commit('setapiname', 12004)
      this.$store.commit('setAPI')
      await axios
        .post(this.$store.getters.API, {
          agent_id: this.$store.getters.agent_id,
        })
        .then((resp) => {
          console.log('setlayout_member')
          if (resp.data.status == 200 || resp.data.status == 201) {
            this.$store.commit('setimg_bg', resp.data.result.background)
            this.img = this.$store.getters.img_bg
            if ((this.img !== null) & (this.img !== '')) {
              this.changeBackgroundImage(this.img)
            }
            // this.color = 'theme_purple'
            this.color = 'theme_' + resp.data.result.theme
            const theme = document.querySelectorAll('.theme')
            theme.forEach((item) => {
              item.classList.remove('theme')
              item.classList.add(this.color)
            })
            this.logo = resp.data.result.logo1
            this.logo2 = resp.data.result.logo2
          }
        })
        .catch((error) => {
          console.log('setlayout_landing', error)
        })
    },
    closeallcollapse_minigame() {
      const list = document.querySelectorAll(
        '.accordion-collapse.collapse.show',
      )
      list.forEach((item) => {
        item.classList.remove('show')
      })
      const arr = document.querySelectorAll('.bi-arrow-up-circle')
      arr.forEach((item) => {
        item.classList.remove('bi-arrow-up-circle')
        item.classList.add('bi-arrow-down-circle')
      })
      document.querySelector('button#btngetticket').click()
    },
    closeallcollapse_promotion() {
      const list = document.querySelectorAll(
        '.accordion-collapse.collapse.show',
      )
      list.forEach((item) => {
        item.classList.remove('show')
      })
      const arr = document.querySelectorAll('.bi-arrow-up-circle')
      arr.forEach((item) => {
        item.classList.remove('bi-arrow-up-circle')
        item.classList.add('bi-arrow-down-circle')
      })
      document.querySelector('button#btnpromotion').click()
    },
    closeallcollapse_commission() {
      const list = document.querySelectorAll(
        '.accordion-collapse.collapse.show',
      )
      list.forEach((item) => {
        item.classList.remove('show')
      })
      const arr = document.querySelectorAll('.bi-arrow-up-circle')
      arr.forEach((item) => {
        item.classList.remove('bi-arrow-up-circle')
        item.classList.add('bi-arrow-down-circle')
      })
      document.querySelector('button#btncommission').click()
    },
    newtab(URL) {
      window.open(URL, '_blank')
    },
    resetvalue() {
      document.querySelector('button#resetvalue_voucher').click()
      document.querySelector('button#resetvalue_wd').click()
    },
    //---------------clear token----------------//
    async cleartoken() {
      setTimeout(function () {
        document.querySelector('button#NECTime').click()
      }, 100)
      this.$store.commit('clearall')
      this.$store.commit('setapiname', 11006)
      this.$store.commit('setAPI')
      const token = sessionStorage.getItem('token')
      const headers = { Authorization: 'Bearer ' + token }
      console.log('cleartoken')
      await axios
        .post(
          this.$store.getters.API,
          {},
          {
            headers,
          },
        )
        .then((res) => {
          console.log(res.data)
          Swal.fire({
            title: 'สำเร็จ!!!',
            text: 'ออกจากระบบสำเร็จ',
            icon: 'success',
            confirmButtonText: 'ตกลง',
          })
          sessionStorage.removeItem('token')
          sessionStorage.removeItem('aid')
          this.$store.commit('clearall')
          this.$router.push('/home')
        })
        .catch((error) => {
          console.error('error clearall =>', error)
        })
    },
    //--------------get profile---------------------//
    async refreshprofile() {
      try {
        document.querySelector('button#btnpromotionall').click()
        document.querySelector('button#btngetnotipromo').click()
      } catch {
        console.log('=====>click btngetnotipromo || btnpromotionall<=====')
      }
      this.$store.commit('setcredit', '-')
      this.$store.commit('setapiname', 11001)
      this.$store.commit('setAPI')
      const token = sessionStorage.getItem('token')
      const headers = { Authorization: 'Bearer ' + token }
      console.log('refreshprofile')
      await axios
        .post(
          this.$store.getters.API,
          {},
          {
            headers,
          },
        )
        .then((res) => {
          if (res.data.status == 200) {
            // ------------------------------------------------------------------------------//
            this.$store.commit(
              'setbkmb',
              res.data.result.profile_mem.banking_account,
            )
            this.$store.commit(
              'setbkacc',
              this.$store.getters.bankmember[0].bank_acct,
            )
            this.$store.commit(
              'setbkname',
              this.$store.getters.bankmember[0].bank_name,
            )
            this.$store.commit(
              'setbknameth',
              this.$store.getters.bankmember[0].bank_name_th,
            )
            this.$store.commit(
              'setbkid',
              this.$store.getters.bankmember[0].bank_id,
            )
            // ------------------------------------------------------------------------------//
            this.$store.commit(
              'setphonenumber',
              res.data.result.profile_mem.profile.tel,
            )
            this.$store.commit(
              'setfname',
              res.data.result.profile_mem.profile.name,
            )
            this.$store.commit(
              'setlname',
              res.data.result.profile_mem.profile.surename,
            )
            this.$store.commit('setidline', res.data.result.profile_mem.line_id)
            this.$store.commit(
              'setcreatedate',
              res.data.result.profile_mem.create_date,
            )
            this.$store.commit(
              'setusername',
              res.data.result.profile_mem.username,
            )
            this.$store.commit(
              'setcredit',
              res.data.result.profile_mem.PD.credit,
            )
            this.$store.commit(
              'setwdc',
              res.data.result.profile_mem.financial.withdraw_count,
            )
            setTimeout(function () {
              try {
                document.querySelector('button#btnstartcountdown').click()
              } catch {
                console.log('=====>click btnstartcountdown<=====')
              }
            }, 100)
          } else if (res.data.status == 503) {
            Swal.fire({
              title: 'ผิดพลาด!!!',
              text: 'พบการ Login ซ้อนกรุณาติดต่อเจ้าหน้าที่หรือ Login ใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'ตกลง',
            })
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('aid')
            this.$store.commit('clearall')
            this.$router.push('/home')
          } else if (res.data.status == 502 || res.data.status == 504) {
            Swal.fire({
              title: 'ผิดพลาด!!!',
              text: 'กรุณา Login ใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'ตกลง',
            })
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('aid')
            this.$store.commit('clearall')
            this.$router.push('/home')
          } else {
            console.log('Call Profile Member : ', res.data.message)
          }
        })
        .catch((error) => {
          console.error('error Call Profile Member =>', error)
          // Swal.fire({
          //   title: 'ผิดพลาด!!!',
          //   text: 'ระบบขัดข้องกรุณา ติดต่อเจ้าหน้าที่',
          //   icon: 'error',
          //   confirmButtonText: 'ตกลง',
          // })
        })
    },
    //-----------------get bank DP--------------//
    async getbankdp() {
      this.$store.commit('setapiname', 11009)
      this.$store.commit('setAPI')
      const token = sessionStorage.getItem('token')
      const headers = { Authorization: 'Bearer ' + token }
      console.log('getbankdp')
      await axios
        .post(
          this.$store.getters.API,
          {},
          {
            headers,
          },
        )
        .then((res) => {
          if (res.data.status == 200) {
            this.$store.commit('setbkdp', res.data.result)
          } else if (res.data.status == 503) {
            Swal.fire({
              title: 'ผิดพลาด!!!',
              text: 'พบการ Login ซ้อนกรุณาติดต่อเจ้าหน้าที่หรือ Login ใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'ตกลง',
            })
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('aid')
            this.$store.commit('clearall')
            this.$router.push('/home')
          } else if (res.data.status == 502 || res.data.status == 504) {
            Swal.fire({
              title: 'ผิดพลาด!!!',
              text: 'กรุณา Login ใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'ตกลง',
            })
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('aid')
            this.$store.commit('clearall')
            this.$router.push('/home')
          } else {
            console.log('Call Bank Deposit : ', res.data.message)
          }
        })
        .catch((error) => {
          console.error('error Call Bank Deposi =>', error)
        })
    },
  },
  async mounted() {
    await this.callwhitelist()
    await this.setlayout()
    document.querySelector('button#NCTime').click()
    if (this.$store.isLoggedIn == false) {
      this.checker = true
    } else {
      this.checker = false
    }
    this.refreshprofile()
    this.getbankdp()
  },
}
</script>

<style scoped>
@import '@/styles/theme_color.css';
</style>
