import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/layouts/LoginLayout.vue'
import Landing from '@/layouts/DefaultLayout.vue'
import MemberView from '@/layouts/MemberLayout.vue'
import loading from '@/views/Loading.vue'

const routes = [
  {
    path: '/',
    name: 'loading',
    component: loading,
    redirect: '/',
    children: [
      {
        path: '/',
        name: '',
        component: () => import('../views/Loading.vue'),
      },
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/Loading.vue'),
      },
      {
        path: '/:page1',
        name: 'page',
        component: () => import('../views/Loading.vue'),
      },
    ],
  },
  {
    path: '/default',
    name: 'landing',
    component: Landing,
    redirect: '/default',
    children: [
      {
        path: '/default',
        name: 'landing',
        component: () => import('../views/Landing.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: HomeView,
    redirect: '/login',
    children: [
      {
        path: '/login',
        name: '',
        component: () => import('../views/Login_page.vue'),
      },
      {
        path: '/login/:state',
        name: 'logintel',
        component: () => import('../views/Login_page.vue'),
      },
    ],
  },
  {
    path: '/register',
    name: 'register',
    component: HomeView,
    redirect: '/register',
    children: [
      {
        path: '/register',
        name: 'register',
        component: () => import('../views/register_page.vue'),
      },
      {
        path: '/:page/:state',
        name: 'regis',
        component: () => import('../views/register_page.vue'),
      },
    ],
  },
  {
    path: '/member',
    name: 'Member',
    component: MemberView,
    redirect: '/member',
    children: [
      {
        path: '/member',
        name: '',
        component: () => import('../views/Member.vue'),
      },
      {
        path: '/member/games',
        name: '',
        component: () => import('../views/GamesList.vue'),
      },
      {
        path: '/member/deposit',
        name: '',
        component: () => import('../views/Deposit_page.vue'),
      },
      {
        path: '/member/withdraw',
        name: '',
        component: () => import('../views/Withdraw_page.vue'),
      },
      {
        path: '/member/history',
        name: '',
        component: () => import('../views/History_page.vue'),
      },
      {
        path: '/member/promotion',
        name: '',
        component: () => import('../views/Promotion_page.vue'),
      },
      {
        path: '/member/minigame',
        name: '',
        component: () => import('../views/MiniGame_page.vue'),
      },
      {
        path: '/member/voucher',
        name: '',
        component: () => import('../views/Voucher_page.vue'),
      },
      {
        path: '/member/commission',
        name: '',
        component: () => import('../views/Commission_page.vue'),
      },
      {
        path: '/member/games/:gametype',
        name: 'gametype',
        component: () => import('../views/GamesList.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
